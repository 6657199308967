import * as types from '../../constants/action_types'

export const initialState = {}

function searchVolume(state = initialState, action) {
  switch (action.type) {
    case types.PENDING_SEARCH_VOLUME_ACTION: {
      const existingState = state[action.payload.id] || {}
      return {
        ...state,
        [action.payload.id]: {
          ...existingState,
          isLoading: true
        }
      }
    }
    case types.RESET_SEARCH_VOLUME_ACTION: {
      const existingState = state[action.payload.id] || {}
      return {
        ...state,
        [action.payload.id]: {
          ...existingState,
          isLoading: false
        }
      }
    }
    case types.SET_SEARCH_VOLUME_DATA: {
      const existingState = state[action.payload.id] || {}
      return {
        ...state,
        [action.payload.id]: {
          ...existingState,
          timeSelectRangeKey: action.payload.timeSelectRangeKey,
          data: action.payload.data
        }
      }
    }
    default:
      return state
  }
}

export default searchVolume
