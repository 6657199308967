import * as type from 'constants/action_types'

export const initialState = {
  download: {
    isLoading: false,
    completed: false
  }
}

function extension(state = initialState, action) {
  switch (action.type) {
    case type.PENDING_EXTENSION_ACTION:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          isLoading: true
        }
      }
    case type.COMPLETED_EXTENSION_ACTION:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          isLoading: false,
          completed: true
        }
      }
    case type.RESET_EXTENSION_ACTION:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          isLoading: false,
          completed: false
        }
      }
    default:
      return state
  }
}

export default extension
