import * as type from 'constants/action_types/checklist'

export const initialState = {
  checklists: [],
  loadChecklistData: {
    isLoading: false
  }
}

function checklist(state = initialState, action) {
  switch (action.type) {
    case type.PENDING_CHECKLIST_ACTION:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          isLoading: true
        }
      }
    case type.RESET_CHECKLIST:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          isLoading: false
        }
      }
    case type.SET_CHECKLIST:
      return {
        ...state,
        checklists: action.payload
      }
    case type.SET_CHECKLIST_ITEM_CHECKED:
      return {
        ...state,
        checklists: state.checklists.map(c => ({
          ...c,
          checklist_items: c.checklist_items.map(item =>
            item.id === action.payload.itemId
              ? {
                  ...item,
                  completed: action.payload.completed
                }
              : item
          )
        }))
      }
    default:
      return state
  }
}

export default checklist
