import * as types from '../../constants/action_types'

export const initialState = {}

function Seasonality(state = initialState, action) {
  switch (action.type) {
    case types.PENDING_SEASONALITY_ACTION: {
      const existingState = state[action.payload.id] || {}
      return {
        ...state,
        [action.payload.id]: {
          ...existingState,
          isLoading: true
        }
      }
    }
    case types.RESET_SEASONALITY_ACTION: {
      const existingState = state[action.payload.id] || {}
      return {
        ...state,
        [action.payload.id]: {
          ...existingState,
          isLoading: false
        }
      }
    }
    case types.SET_SEASONALITY_DATA: {
      const existingState = state[action.payload.id] || {}
      return {
        ...state,
        [action.payload.id]: {
          ...existingState,
          data: action.payload.data
        }
      }
    }
    default:
      return state
  }
}

export default Seasonality
