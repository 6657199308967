import merge from 'lodash/merge'
import cloneDeep from 'lodash/cloneDeep'

import { RESET_PRODUCT_DATABASE } from 'constants/action_types/product_database'
import * as type from 'constants/action_types'
import { DEFAULT_DATABASE_FILTERS } from 'constants/filters'
import { PRODUCT_DATABASE_TABLE_V2_COLUMNS } from 'constants/product_database/table_v2_columns'

import { getShowState } from 'helpers/core'
import { updateInArray, findReverseElements } from 'helpers/arrays'

export const initialState = {
  isLoading: false,
  isExportingCsv: false,
  data: {
    total_count: 0,
    total_pages: 1,
    selectedPage: 1,
    products: [],
    tiers: [],
    sellerTypes: [
      { order: 1, label: 'FBA', aggregationLabel: 'FBA', value: 'FBA' },
      { order: 2, label: 'FBM', aggregationLabel: 'FBM', value: 'Merch' },
      { order: 3, label: 'Amazon', aggregationLabel: 'AMZ', value: 'AMZ' }
    ],
    selectedProduct: null,
    currencyCode: 'USD',
    selectedCountry: 'us'
  },

  filters: DEFAULT_DATABASE_FILTERS,

  presets: {
    isLoading: false,
    name: '',
    userPresets: [],
    jsPresets: [],
    selectedPreset: {}
  },

  variantsStatus: {},
  showPresetSave: false,
  showPresetLoad: false,
  showPresetDelete: false,
  excludeTopBrands: false,
  completedSearch: false,
  selectedColumns: PRODUCT_DATABASE_TABLE_V2_COLUMNS
}

const getProducts = (state, parentProductId = null) => {
  if (parentProductId === null) return state.data.products

  const parentProduct = state.data.products.find(
    item => item.id === parentProductId
  )

  return parentProduct.variants
}

const updateProducts = (state, newProducts, parentProductId = null) => {
  if (!parentProductId) {
    return {
      ...state,
      data: {
        ...state.data,
        products: newProducts
      }
    }
  }

  const parentProductIndex = state.data.products.findIndex(
    item => item.id === parentProductId
  )
  const parentProductOld = state.data.products[parentProductIndex]
  const parentProductNew = { ...parentProductOld, variants: newProducts }

  const parentProductsNew = [
    ...state.data.products.slice(0, parentProductIndex),
    parentProductNew,
    ...state.data.products.slice(
      parentProductIndex + 1,
      state.data.products.length
    )
  ]

  return {
    ...state,
    data: {
      ...state.data,
      products: parentProductsNew
    }
  }
}

const updateProductVariant = (state, action, newProperties) => {
  const parentProduct = state.data.products.find(
    product => product.asin === action.parentAsin
  )
  const updatedVariants = updateInArray(
    parentProduct.variants,
    { asin: action.asin },
    newProperties
  )
  const products = updateInArray(
    state.data.products,
    { asin: action.parentAsin },
    {
      variantAsins: updatedVariants,
      variants: updatedVariants,
      subRows: updatedVariants
    }
  )

  return { ...state.data, products }
}

function databaseReducer(
  state = {
    ...cloneDeep(initialState),
    selectedColumns: PRODUCT_DATABASE_TABLE_V2_COLUMNS
  },
  action
) {
  switch (action.type) {
    case type.SET_PRODUCT_DATABASE_DATA:
      return { ...state, ...action.payload }
    case type.PENDING_PRODUCT_SEARCH:
      return {
        ...state,
        isLoading: true
      }
    case type.PENDING_PRODUCT_VARIANTS_SEARCH: {
      const { scrapedParentAsin } = action.payload

      const products = updateInArray(
        state.data.products,
        { asin: scrapedParentAsin },
        { variants: [] }
      )

      const data = { ...state.data, products }

      const variantsStatus = {
        ...state.variantsStatus,
        [scrapedParentAsin]: { isLoadingVariants: true, error: null }
      }

      return { ...state, data, variantsStatus }
    }
    case type.FAILED_PRODUCT_SEARCH:
      return {
        ...state,
        isLoading: false
      }
    case type.FAILED_PRODUCT_VARIANTS_SEARCH: {
      const { scrapedParentAsin } = action.payload
      const variantsStatus = {
        ...state.variantsStatus,
        [scrapedParentAsin]: { isLoadingVariants: false, error: true }
      }
      return { ...state, variantsStatus }
    }
    case type.COMPLETED_PRODUCT_SEARCH: {
      const { products } = action.data
      const { sort } = action.filters
      /* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["product"] }] */
      if (sort?.column === 'estimatedSales')
        findReverseElements(
          products,
          v => v?.estimatedSales,
          sort?.direction
        ).forEach(product => {
          product.isEstimatedSalesWrongOrder = true
        })
      return {
        ...state,
        data: {
          ...state.data,
          ...action.data
        },
        filters: {
          ...state.filters,
          ...action.filters
        },
        isLoading: false,
        completedSearch: true
      }
    }
    case type.COMPLETED_PRODUCT_VARIANTS_SEARCH: {
      const products = updateInArray(
        state.data.products,
        { asin: action.scrapedParentAsin },
        {
          variantAsins: action.data.products,
          variants: action.data.products,
          subRows: action.data.products
        }
      )

      const data = { ...state.data, products }

      const variantsStatus = {
        ...state.variantsStatus,
        [action.scrapedParentAsin]: { isLoadingVariants: false, error: null }
      }

      return { ...state, data, variantsStatus }
    }
    case type.SORT_DATABASE:
      return {
        ...state,
        filters: {
          ...state.filters,
          sort: {
            ...state.filters.sort,
            column: action.column,
            direction: action.direction
          }
        }
      }
    case type.SET_DATABASE_COUNTRY:
      return {
        ...state,
        data: {
          ...state.data,
          currencyCode: action.payload.currencyCode,
          selectedCountry: action.payload.country
        },
        filters: {
          ...state.filters,
          country: {
            ...state.filters.country,
            valuesArray: [action.payload.country]
          },
          calculatedCategory: {
            ...state.filters.calculatedCategory,
            valuesArray: initialState.filters.calculatedCategory.valuesArray
          }
        }
      }
    case type.SET_DATABASE_SELECTED_CATEGORIES:
      return {
        ...state,
        filters: {
          ...state.filters,
          calculatedCategory: {
            ...state.filters.calculatedCategory,
            valuesArray: action.categories
          }
        }
      }
    case type.SET_DATABASE_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.name]: {
            ...state.filters[action.name],
            [action.property]: action.value
          }
        }
      }
    case type.SET_DATE_FIRST_AVAILABLE_RANGE:
      return {
        ...state,
        data: {
          ...state.data
        },
        filters: {
          ...state.filters,
          listedAt: {
            ...state.filters.listedAt,
            min: action.payload.startDate,
            max: action.payload.endDate
          }
        }
      }
    case type.SET_DATABASE_PAGE:
      return {
        ...state,
        data: {
          ...state.data,
          selectedPage: action.page
        },
        filters: {
          ...state.filters,
          paginate: {
            ...state.filters.paginate,
            from: action.from
          }
        }
      }
    case type.EDIT_DATABASE_PAGE:
      return {
        ...state,
        data: {
          ...state.data,
          selectedPage: action.page
        }
      }

    case type.RESET_DATABASE_FILTERS:
      return {
        ...state,
        filters: {
          ...cloneDeep(initialState.filters),
          country: state.filters.country
        },
        presets: {
          ...initialState.presets,
          name: ''
        },
        data: {
          ...state.data
        },
        excludeTopBrands: initialState.excludeTopBrands
      }
    case type.PENDING_PRODUCT_VARIANT_REFRESH: {
      const data = updateProductVariant(state, action, { isLoading: true })

      return { ...state, data }
    }
    case type.COMPLETED_PRODUCT_VARIANT_REFRESH: {
      const data = updateProductVariant(state, action, {
        ...action.product,
        isLoading: false
      })

      return { ...state, data }
    }
    case type.FAILED_PRODUCT_VARIANT_REFRESH: {
      const data = updateProductVariant(state, action, {
        isLoading: false
      })

      return { ...state, data }
    }
    case type.PENDING_PRODUCT_REFRESH: {
      const newProducts = updateInArray(
        getProducts(state, action.parentProductId),
        { asin: action.asin },
        {
          isLoading: true
        }
      )

      return updateProducts(state, newProducts, action.parentProductId)
    }
    case type.COMPLETED_PRODUCT_REFRESH: {
      const newProducts = updateInArray(
        getProducts(state, action.parentProductId),
        { asin: action.asin },
        { ...action.product, isLoading: false }
      )

      return updateProducts(state, newProducts, action.parentProductId)
    }
    case type.FAILED_PRODUCT_REFRESH: {
      const newProducts = updateInArray(
        getProducts(state, action.parentProductId),
        { asin: action.asin },
        { isLoading: false }
      )

      return updateProducts(state, newProducts, action.parentProductId)
    }
    case type.TOGGLE_DATABASE_DROPDOWN: {
      return {
        ...state,
        [action.payload.prop]: getShowState(state[action.payload.prop])
      }
    }
    case type.TOGGLE_DATABASE_SAVE_PRESET: {
      const newShowState = getShowState(state.showPresetSave, action.show)
      return {
        ...state,
        showPresetSave: newShowState
      }
    }
    case type.TOGGLE_DATABASE_LOAD_PRESET: {
      const newShowState = getShowState(state.showPresetLoad, action.show)
      return {
        ...state,
        showPresetLoad: newShowState
      }
    }
    case type.TOGGLE_DATABASE_DELETE_PRESET: {
      const newShowState = getShowState(state.showPresetDelete, action.show)
      return {
        ...state,
        showPresetDelete: newShowState,
        showPresetLoad: !newShowState
      }
    }
    case type.TOGGLE_EXCLUDE_TOP_BRANDS: {
      return {
        ...state,
        excludeTopBrands: !state.excludeTopBrands
      }
    }
    case type.UPDATE_DATABASE_PRESET_NAME: {
      return {
        ...state,
        presets: {
          ...state.presets,
          name: action.value
        }
      }
    }
    case type.TOGGLE_MULTIUSER_SHARING: {
      return {
        ...state,
        presets: {
          ...state.presets,
          is_private: action.is_private
        }
      }
    }
    case type.SELECT_DATABASE_PRESET: {
      return {
        ...state,
        presets: {
          ...state.presets,
          selectedPreset: action.value
        }
      }
    }
    case type.PENDING_DATABASE_PRESET: {
      return {
        ...state,
        presets: {
          ...state.presets,
          isLoading: action.isLoading
        }
      }
    }
    case type.SET_DATABASE_PRESETS: {
      return {
        ...state,
        presets: {
          ...state.presets,
          userPresets: action.userPresets,
          jsPresets: action.jsPresets
        }
      }
    }
    case type.LOAD_DATABASE_PRESET: {
      const selectedCountry = action.filters.country
        ? action.filters.country.valuesArray[0]
        : state.data.selectedCountry

      let filters = {
        ...cloneDeep(initialState.filters),
        calculatedCategory: cloneDeep(state.filters.calculatedCategory)
      }

      filters = merge(filters, action.filters)

      return {
        ...state,
        data: {
          ...state.data,
          selectedCountry
        },
        filters
      }
    }
    case RESET_PRODUCT_DATABASE: {
      return {
        ...cloneDeep(initialState)
      }
    }
    case type.PENDING_DATABASE_CSV_EXPORT: {
      return {
        ...state,
        isExportingCsv: true
      }
    }
    case type.COMPLETED_DATABASE_CSV_EXPORT: {
      return {
        ...state,
        isExportingCsv: false
      }
    }
    default:
      return state
  }
}

export default databaseReducer
